import React, { useState, useEffect, useCallback } from "react";
import { useOutletContext } from "react-router-dom";
import ButtonView from "../../atoms/ButtonView/ButtonView";
import {
  getEvents,
  selectFilteredEventIdsbyCity,
} from "../../../features/events/eventsSlice";
import { dateFilterChanged } from "../../../features/filters/filtersSlice";
import { useSelector, useDispatch } from "react-redux";
import EventItem from "../../../features/events/EventItem";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
// import FiltersView from "../../organisms/FiltersView/FiltersView";
import { ButtonGroup } from "@mui/material";
import LabelView from "../../atoms/LabelView/LabelView";
import {
  lightBlueColor,
  muiColors,
  paletteRed,
} from "../../../utils/globalStyles";
import { cityLocations } from "../../../utils/Constants";
import LoadingSpinner from "../../atoms/LoadingSpinner/LoadingSpinner";

export default function EventFeedView(props) {
  const { isMobile } = useOutletContext();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  dayjs.extend(advancedFormat);

  const futureDays = useSelector((state) => state.filters.futureDays);

  // async in effeccts is weird. might be better to use suspense
  // https://news.ycombinator.com/item?id=35108672 search "useSomeDataLibrary"
  // https://react.dev/learn/synchronizing-with-effects#fetching-data
  // This is firing twice...because of React.StrictMode
  // useEffect(() => {
  //   // let ignore = false;

  //   // This kinda works I guess, getEvents runs twice, but it looks like the ignore thing is somehow very stupid. Thanks React docs
  //   // async function startFetching() {
  //   //   const json = await getEvents();
  //   //   if (!ignore) {
  //   //     dispatch(json);
  //   //   }
  //   // }

  //   // This is my attempt to take a response from updateEvent and pass it into redux,
  //   // so that getEvents() doesn't have to be called if the events are already returned in updateEvent.
  //   // Redux seems to be working properly, but getting this pace to refresh conditionally/properly is not going so great.

  //   // const fetchEvents = async () => {
  //   //   // Access state from
  //   //   console.log("YOOOOO", location, location.state?.updatedEvents);
  //   //   if (location.state && location.state.updatedEvents) {
  //   //     // console.log(location.state.updatedEvents);
  //   //     dispatch(setEvents(location.state.updatedEvents));
  //   //     // Update your component state or perform other actions
  //   //   } else {
  //   //     // If no events in the state, fetch them
  //   //     // const events = await getEvents();

  //   //     // Update your component state or perform other actions
  //   //     dispatch(getEvents());
  //   //   }
  //   // };
  //   // fetchEvents();

  //   dispatch(getEvents());
  //   return () => {
  //     // ignore = true;
  //   };
  // }, [dispatch]);

  useEffect(() => {
    setIsLoading(true); // Start loading

    dispatch(getEvents()).finally(() => {
      setIsLoading(false); // Stop loading once the events are fetched
    });
  }, [dispatch]);

  const selectDateFilter = useCallback(
    (futureDays) => {
      dispatch(dateFilterChanged(futureDays));
    },
    [dispatch]
  );

  useEffect(() => {
    selectDateFilter(0);
  }, [selectDateFilter]);

  const futureDateFilter = [...Array(isMobile ? 7 : 7)].map((_, idx) => {
    // const dateText =
    //   idx === 0
    //     ? isMobile
    //       ? "Tod"
    //       : "Today"
    //     : idx === 1
    //     ? "Tom" // abbrev for mobile
    //     : dayjs().add(idx, "day").format("dd D/M");

    const dateText = dayjs().add(idx, "day").format("ddd Do");

    return (
      <ButtonView
        size={isMobile ? "small" : undefined}
        key={dateText}
        text={dateText}
        variant={futureDays === idx ? "contained" : undefined}
        color={muiColors.paletteRed}
        onClick={() => selectDateFilter(idx)}
        sx={{ textTransform: "none" }}
      />
    );
  });

  const EventsByCityContainer = () => {
    const eventIdsByCity = useSelector(selectFilteredEventIdsbyCity);
    const usedCityLocations = Object.keys(eventIdsByCity);
    const sortedCityContainers = cityLocations.filter((city) =>
      usedCityLocations.includes(city)
    );

    const noEventsView = (
      <div style={{ width: isMobile ? undefined : 584, marginTop: 12 }}>
        <LabelView text="No events currently scheduled for this day. Check back later!" />
      </div>
    );

    // pre-mapped so that all events belong to a single container
    const mappedEvents = sortedCityContainers.map((cityName, idx) => (
      <div
        key={`${cityName} - ${idx}`}
        style={{ width: isMobile ? "100%" : "", marginBottom: 8 }}
      >
        <LabelView
          text={cityName}
          type={"smalltitle"}
          style={{ marginBottom: 8 }}
        />
        <div style={styles.eventsContainer}>
          {eventIdsByCity[cityName].map((id) => (
            <EventItem key={id} id={id} isMobile={isMobile} isFeed />
          ))}
        </div>
        {/* {isMobile && <MobileDummyAnnoucmement />} */}
      </div>
    ));

    // These are all very flexible. 1050 doesn't actually mean much
    // A container with many events will wrap within itself first
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {mappedEvents.length ? mappedEvents : noEventsView}
      </div>
    );
  };

  // const DummyAnnoucement = () => (
  //   <div style={styles.dummyAnnoucement}> Announcement will go here </div>
  // );
  // const MobileDummyAnnoucmement = () => (
  //   <div style={styles.mobileDummyAnnoucement}> Announcement will go here </div>
  // );

  // const AnnouncementsContainer = () => {
  //   return (
  //     <div style={styles.announcementsContainer}>
  //       <DummyAnnoucement />
  //       <DummyAnnoucement />
  //     </div>
  //   );
  // };

  const dynamicDateFilter = {
    ...styles.dateFilter,
    marginBottom: isMobile ? undefined : 16,
  };

  return (
    <div style={styles.container}>
      {/* {!isMobile && <FiltersView />} */}

      <div style={styles.feedContainer}>
        <div style={dynamicDateFilter}>
          <ButtonGroup
            variant="outlined"
            style={{ marginBottom: isMobile ? 8 : undefined }}
          >
            {futureDateFilter}
          </ButtonGroup>
        </div>
        <div style={{ display: "flex", width: isMobile ? "100%" : undefined }}>
          {isLoading ? (
            <LoadingSpinner
              style={styles.loadingSpinner}
              isLoading={isLoading}
              color={paletteRed}
            />
          ) : (
            <EventsByCityContainer />
          )}
        </div>
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    height: "100%",
  },
  dateFilter: {
    display: "flex",
    borderRadius: 4,
  },
  feedContainer: {
    width: "100%",
    padding: 16,
  },
  eventsContainer: {
    display: "flex",
    flexWrap: "wrap",
    margin: "0px 10px",
  },
  loadingSpinner: {
    display: "flex",
    justifyContent: "center",
    marginTop: 12,
    width: "100%",
  },
  announcementsContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "20px auto",
  },
  dummyAnnoucement: {
    height: 300,
    width: 400,
    margin: 8,
    background: lightBlueColor,
  },
  mobileDummyAnnoucement: {
    height: 100,
    margin: 8,
    background: lightBlueColor,
  },
};
